<div class="app-header--bar ion-padding-horizontal text-area--level">
  <div class="app-header--logo">
    <a
      href="/"
      (click)="logoClick($event)"
      class="app-header--logo-link"
    >
      <img
        *ngIf="logoUrl || fallbackLogoUrl"
        [src]="logoUrl || fallbackLogoUrl"
        alt=""
        role="presentation"
        class="app-header--logo-img"
      />
      <strong
        class="app-header--logo-title text-style--alt text-style--size-08 text-style--use-newlines"
      >
        {{ title || fallbackTitle }}
      </strong>
    </a>
  </div>

  <div class="app-header--tools no-print">
    <app-translation-options
      [sourceLanguage]="currentLanguage"
      [localeAlternatives]="localeAlternatives"
      [languageOptionsExplanation]="languageOptionsExplanation"
    >
    </app-translation-options>
  </div>
</div>
